import React from 'react';

const ModalWrapper = ({show, element, onDeleteHandler, closeModal, title = '', header = ''}) => {
    return (
        <div className={show ? 'modal d-flex' : 'd-none'}>
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-white" id="exampleModalLongTitle">{header}</h5>
                        <button type="button" className="close" onClick={closeModal}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <h5>{title}</h5>
                    </div>
                    <div className="modal-footer justify-content-around">
                        <button type="button" className="btn btn-secondary" onClick={closeModal}>Close</button>
                        <button type="button" className="btn btn-danger" onClick={() => onDeleteHandler(element._id)}>Delete</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalWrapper;