import React, { useEffect, useState } from 'react';
import { useHistory, Link } from "react-router-dom";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import TableActionButtton from '../../components/hoc/tableActionButtton';
import { useForm } from 'react-hook-form';
import { addNewProperty, getPropertyList, deleteSingleProperty, updateProperty } from '../../store/services/property';
import ModalWrapper from '../../components/hoc/modalWrapper';
import Loader from "react-loader-spinner";
import { useSelector, useDispatch } from 'react-redux';
import Toast from '../../components/toast/Toast';
import { getOwner } from '../../store/services/owner';
import { OWNER_LIST, PROPERTY_LIST } from '../../store/actions/actions';

function PropertyList() {
	const [showPropertyList, setShowPropertyList] = useState(true)
	const [propertyList, setPropertyList] = useState([]);
	const [edit, setEdit] = useState(false);
	const [singleProperty, setSingleProperty] = useState({});
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [loader, setLoader] = useState(true);
	const [list, setList] = useState([]);

	const dispatch = useDispatch();
	let history = useHistory();


	const sidebar = useSelector(state => state.sidebar);

	const owner = useSelector(state => state.owner);


	const columns = [ 
	{
		dataField: 'propertyName',
		text: 'PG Name',
	}, {
		dataField: 'pgEmail',
		text: 'PG Email'
	}, {
		dataField: 'ownerName',
		text: 'PG Owner'
	}, {
		dataField: 'pgContact',
		text: 'PG Contact'
	},
	{
		dataField: '',
		text: 'Occupancy',
		formatter: (cell, row) => {
			if(row.bedCount){
				return <div>{row.bedCount}</div>
			} else {
				return <div>0</div>
			}
		}
	},
	{
		dataField: 'action',
		text: 'Action',
	}];

	let ActonButtonTooltips=["Manage rooms","Edit property","Delete property"];

	useEffect(async () => {
		getOwnerList()
		let propertyTempList = []
		setLoader(true);
		const response = await getPropertyList();
		if (response.length >= 0) {
			dispatch({type: PROPERTY_LIST, payload: response})
			setLoader(false);
		}
		propertyTempList = response;
		if (propertyTempList.length > 0) {
			propertyTempList.forEach((element) => {
				element.action = (
					<TableActionButtton deleteFn={deleteProperty} editFn={editProperty} routeToRoomFn={routeToRoom} buttonTooltips={ActonButtonTooltips} element={element} />
				);
			});
		}
		setPropertyList(propertyTempList);
		return () => {
			setEdit(false)
		}
	}, [])

	useEffect(() => {
		setShowPropertyList(true);
	}, [sidebar])

	const getOwnerList = async () => {
		try {
			let response = await getOwner();
			if (response) {
				dispatch({ type: OWNER_LIST, payload: response })
			}
		} catch (err) {
			if (err.message) {
				errorToast(err.message)
			}
		}
	}

	const updateTable = (element, id = '') => {
		let oldList = [...propertyList];
		if (id === "") {
			oldList = [...oldList, element]
			oldList.forEach((element) => {
				element.action = (
					<TableActionButtton
						deleteFn={deleteProperty}
						editFn={editProperty}
						routeToRoomFn={routeToRoom}
						buttonTooltips={ActonButtonTooltips}
						element={element}
					/>
				);
			});
			setPropertyList(oldList);
		} else {
			var foundIndex = oldList.findIndex(x => x._id == id);
			element.action = (
				<TableActionButtton
					deleteFn={deleteProperty}
					editFn={editProperty}
					routeToRoomFn={routeToRoom}
					buttonTooltips={ActonButtonTooltips}
					element={element}
				/>
			);
			oldList[foundIndex] = element;

			setPropertyList(oldList);
		}
	}

	const errorToast = (error) => {
		let toastProperties = {
			id: 0,
			title: 'Error!',
			description: error,
			backgroundColor: '#d9534f',
			// icon: errorIcon
		}
		setList([...list, toastProperties]);
	}

	const successToast = (success) => {
		let toastProperties = {
			id: 0,
			title: 'Success!',
			description: success,
			backgroundColor: '#34A853',
			// icon: errorIcon
		}
		setList([...list, toastProperties]);
	}

	const routeToRoom = (element) => {
		history.push({
			pathname: '/rooms-list',
			state: { propertyId: element._id, propertyName: element.propertyName }
		})
	}

	const editProperty = (property) => {
		setSingleProperty(property);
		setShowPropertyList(false);
		setEdit(true);
	}

	const deleteProperty = (property) => {
		setSingleProperty(property);
		setShowDeleteModal(true);
	}

	const onDeleteHandler = async (id) => {
		try {
			const deleteResp = await deleteSingleProperty(id);
			if(deleteResp){
				let tempList = propertyList;
				tempList = tempList.filter(tL => tL._id != id);
				setPropertyList(tempList);
				setShowDeleteModal(false);
				successToast('Property Deleted Successfully.');
			}
		} catch (err) {
			if (err.message) {
				errorToast(err.message)
			}
		}
	}

	const closeModal = () => {
		setShowDeleteModal(false);
	}

	return (
		<div>
			<Toast
				toastList={list}
				position={"top-right"}
				autoDelete={true}
				dismissTime={3000}
			/>
			<div className="pcoded-main-container">
				<ModalWrapper
					header="Delete"
					title="Do you want to delete this property?"
					show={showDeleteModal}
					element={singleProperty}
					onDeleteHandler={onDeleteHandler}
					closeModal={closeModal}
				/>
				<div className="card p-3 mb-0">
					<div className="d-flex justify-content-between align-items-center">
						{
							!edit ?
								<h6 className="m-0">{(showPropertyList) ? `List Property` : `Add Property`}</h6>
								:
								<h6 className="m-0">{(!showPropertyList && edit) ? `Edit Property` : ``}</h6>
						}
						<div className="">
							<button className="text-capitalize btn btn-info m-0" onClick={() => {
								setShowPropertyList(!showPropertyList)
								setEdit(false)
							}}><i className="feather icon-plus"></i>{showPropertyList ? `Add Property` : `List Property`}</button>
						</div>
					</div>
				</div>
				{showPropertyList ?
					<div className="pcoded-wrapper">
						<div className="pcoded-content">
							<div className="pcoded-inner-content">
								<div className="row">
									<div className="col-lg-12 col-md-12">
										<div className={!loader ? "card" : ''}>
											{
												!loader ?
													<>
														<div className="card-body">
															<BootstrapTable keyField='id' data={propertyList} columns={columns} />
														</div>
														{
															propertyList.length <= 0 ?
																<div className="card-body text-center pt-0">
																	<span className="font-weight-bold h6">No property found.</span>
																</div> : null
														}
													</>
													:
													<div className="text-center">
														<Loader
															type="Puff"
															color="#00BFFF"
															height={30}
															width={100}
														/>
													</div>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					:
					<PropertyAddEdit showPropertyList={showPropertyList} setShowPropertyList={setShowPropertyList} errorToast={errorToast} updateTable={updateTable} edit={edit} singleProperty={singleProperty} successToast={successToast} />
				}
			</div>
		</div>
	);
}

const PropertyAddEdit = ({ showPropertyList, setShowPropertyList, errorToast, edit = false, singleProperty, updateTable, successToast }) => {


	let roomsArr = [
		{name: "basement", value: "Basement", checked: false},
        {name: "groundFloor", value: "Ground Floor", checked: false},
        {name: "firstFloor", value: "First Floor", checked: false},
        {name: "secondFloor", value: "Second Floor", checked: false},
        {name: "thirdFloor", value: "Third Floor", checked: false},
        {name: "fourthFloor", value: "Fourth Floor", checked: false},
        {name: "fifthFloor", value: "Fifth Floor", checked: false},
        {name: "sixthFloor", value: "Sixth Floor", checked: false},
        {name: "seventhFloor", value: "Seventh Floor", checked: false},
        {name: "eighthFloor", value: "Eighth Floor", checked: false},
        {name: "ninthFloor", value: "Ninth Floor", checked: false},

		{name: "tenthFloor", value: "Tenth Floor", checked: false},
		{name: "eleventhFloor", value: "Eleventh Floor", checked: false},
		{name: "twelvethFloor", value: "Twelveth Floor", checked: false},
		{name: "thirteenthFloor", value: "Thirteenth Floor", checked: false},
		{name: "fourteenthFloor", value: "Fourteenth Floor", checked: false},
		{name: "fifteenthFloor", value: "Fifteenth Floor", checked: false},
    ];
	const owner = useSelector(state => state.owner);

	const [pgName, setPgName] = useState('');
	const [pgEmail, setPgEmail] = useState('');
	const [propertyOwner, setPropertyOwner] = useState('');
	const [managersName, setManagersName] = useState('');
	const [pgContact, setPgContact] = useState('');
	const [availableFor, setAvailableFor] = useState('');
	const [preferredTenants, setPreferredTenants] = useState('');
	const [addressLine1, setAddressLine1] = useState('');
	const [addressLine2, setAddressLine2] = useState('');
	const [city, setCity] = useState('');
	const [state, setState] = useState('');
	const [propertyPincode, setPropertyPincode] = useState('');
	const [checkedState, setCheckedState] = useState(
        {
            "basement": false,
            "groundFloor": false,
            "firstFloor": false,
            "secondFloor": false,
            "thirdFloor": false,
            "fourthFloor": false,
            "fifthFloor": false,
            "sixthFloor": false,
            "seventhFloor": false,
            "eighthFloor": false,
            "ninthFloor": false,
			"tenthFloor": false,
			"eleventhFloor": false,
			"twelvethFloor": false,
			"thirteenthFloor": false,
			"fourteenthFloor": false,
			"fifteenthFloor": false,
          }
    );

	const [pgNameError, setPgNameError] = useState('');
	const [pgEmailError, setPgEmailError] = useState('');
	const [propertyOwnerError, setPropertyOwnerError] = useState('');
	const [managersNameError, setManagersNameError] = useState('');
	const [pgContactError, setPgContactError] = useState('');
	const [availableForError, setAvailableForError] = useState('');
	const [preferredTenantsError, setPreferredTenantsError] = useState('');
	const [addressLine1Error, setAddressLine1Error] = useState('');
	const [addressLine2Error, setAddressLine2Error] = useState('');
	const [cityError, setCityError] = useState('');
	const [stateError, setStateError] = useState('');
	const [propertyPincodeError, setPropertyPincodeError] = useState('');
    const [floorError, setFloorError] = useState('');

	const handleOnChange = async (key) => {
        let newCheckedState = {...checkedState};
        newCheckedState[key] = !newCheckedState[key]
        setCheckedState(newCheckedState)
		setFloorError('')
    }

	const emailValidator = (value) =>
		value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
			? true
			: false;

	const phoneValidator = (value) =>
		value && !/^[0-9]+$/i.test(value)
			? true
			: false;

	useEffect(() => {
		if(edit){
			updateFields()
		}
	}, [edit])

	const updateFields = () => {
		setPgName(singleProperty.propertyName);
		setPgEmail(singleProperty.pgEmail);
		setPropertyOwner(singleProperty.propertyOwner);
		setManagersName(singleProperty.managersName);
		setPgContact(singleProperty.pgContact);
		setAvailableFor(singleProperty.availableFor);
		setPreferredTenants(singleProperty.preferredTenants);
		setAddressLine1(singleProperty.addressLine1);
		setAddressLine2(singleProperty.addressLine2);
		setCity(singleProperty.city);
		setState(singleProperty.state);
		setPropertyPincode(singleProperty.propertyPincode);
		setCheckedState(singleProperty.floor);
	}

	const validate = () => {
		let pgNameError = '';
		let pgEmailError = '';
		let propertyOwnerError = '';
		let managersNameError = '';
		let pgContactError = '';
		let availableForError = '';
		let preferredTenantsError = '';
		let addressLine1Error = '';
		let cityError = '';
		let stateError = '';
		let propertyPincodeError = '';
		let floorError = '';
		if (pgName === '') {
			pgNameError = "PG name is required"
		}
		if (pgEmail === '') {
			pgEmailError = "PG email is required"
		}
		if (emailValidator(pgEmail)) {
			pgEmailError = "Email is not valid"
		}
		if (propertyOwner === '') {
			propertyOwnerError = 'Property Owner is required.';
		}
		if (managersName === '') {
			managersNameError = 'Manager name is required';
		}
		if (pgContact === '') {
			pgContactError = 'Phone number  is required';
		}
		if (phoneValidator(pgContact)) {
			pgContactError = 'Phone number must contain only digits';
		}
		if (!phoneValidator(pgContact) && (pgContact.length < 10 || pgContact.length > 10)) {
			pgContactError = 'Phone number must be of 10 digits';
		}
		if (availableFor === '') {
			availableForError = 'This field is required';
		}
		if (preferredTenants === '') {
			preferredTenantsError = "This field is required"
		}
		if (addressLine1 === '') {
			addressLine1Error = "Address is required"
		}
		if (city === '') {
			cityError = "City is required"
		}
		if (state === '') {
			stateError = "State is required"
		}
		if(propertyPincode === '') {
			propertyPincodeError = "Pincode is required"
		}
		if (propertyPincode.length < 6 || propertyPincode.length > 6) {
			propertyPincodeError = "Pincode must be of 6 digits"
		}
		if(!Object.keys(checkedState).some(k => checkedState[k]  && k != '__v' && k != '_id' && k != 'created' && k != 'propertyId')){
            floorError = "Floor is required"
        }
		if (pgNameError ||
			pgEmailError ||
			propertyOwnerError ||
			managersNameError ||
			pgContactError ||
			availableForError ||
			preferredTenantsError ||
			addressLine1Error ||
			cityError ||
			stateError ||
			propertyPincodeError ||
			floorError) {
			setPgNameError(pgNameError);
			setPgEmailError(pgEmailError);
			setPropertyOwnerError(propertyOwnerError);
			setManagersNameError(managersNameError);
			setPgContactError(pgContactError);
			setAvailableForError(availableForError);
			setPreferredTenantsError(preferredTenantsError)
			setAddressLine1Error(addressLine1Error);
			setCityError(cityError);
			setStateError(stateError);
			setPropertyPincodeError(propertyPincodeError);
			setFloorError(floorError)
			return false;
		}
		return true
	};

	const onSubmit = async () => {
		if(!edit){
			try {
				const isValid = validate();
				if (isValid) {
					// await clearError();
					const data = {
						propertyName: pgName,
						pgEmail: pgEmail,
						propertyOwner: propertyOwner,
						managersName: managersName,
						pgContact: pgContact,
						availableFor: availableFor,
						preferredTenants: preferredTenants,
						addressLine1: addressLine1,
						addressLine2: addressLine2,
						city: city,
						state: state,
						propertyPincode: propertyPincode,
						floor: checkedState,
					}
					const response = await addNewProperty(data);
					if (response) {
						setShowPropertyList(true);
						updateTable(response);
						successToast('Property Added Successfully.');
					}
				}
			} catch (err) {
				if (err.message) {
                    errorToast(err.message);
                }
			}
		} else {
			onSubmitEdit()
		}
	}

	const onSubmitEdit = async() => {
		try {
			const isValid = validate();
			if (isValid) {
				// await clearError();
				const data = {
					propertyName: pgName,
					pgEmail: pgEmail,
					propertyOwner: propertyOwner,
					managersName: managersName,
					pgContact: pgContact,
					availableFor: availableFor,
					preferredTenants: preferredTenants,
					addressLine1: addressLine1,
					addressLine2: addressLine2,
					city: city,
					state: state,
					propertyPincode: propertyPincode,
					floor: checkedState,
				}
				const response = await updateProperty(data, singleProperty._id);
				if (response) {
					setShowPropertyList(true);
					updateTable(response, singleProperty._id);
					successToast('Property Edited Successfully.');
				}
			}
		} catch (err) {
			if (err.message) {
				errorToast(err.message);
			}
		}
	}

	return (
		<div className="pcoded-wrapper">
			<div className="pcoded-content">
				<div className="pcoded-inner-content">
					<div className="row">
						<div className="col-lg-12 col-md-12">
							<div className="card">
								<div className="card-body">
									<form>
										<div className="form-row">
											<div className="form-group mb-0 col">
												<label htmlFor="propertyName" className="form-label">PG Name</label>
												{/* <input type="text" id="propertyName" className="form-control" {...register('propertyName')} /> */}
												<Input className="mb-3 d-block" type="text" placeholder="PG Name" value={pgName} set={setPgName} onFocus={() => setPgNameError('')} validation={pgNameError} />
											</div>
											<div className="form-group mb-0 col">
												<label htmlFor="pgEmail" className="form-label">PG Email</label>
												{/* <input type="text" id="pgEmail" className="form-control" {...register('pgEmail')} /> */}
												<Input className="mb-3 d-block" type="text" placeholder="PG Email" value={pgEmail} set={setPgEmail} onFocus={() => setPgEmailError('')} validation={pgEmailError} />
											</div>
										</div>
										<div className="form-row">
											<div className="form-group mb-0 col">
												<label htmlFor="managersName" className="form-label">Manager Name</label>
												<Input className="mb-3 d-block" type="text" maxLength="20" placeholder="Manager Name" value={managersName} set={setManagersName} onFocus={() => setManagersNameError('')} validation={managersNameError} />

											</div>
											<div className="form-group mb-0 col">
												<label htmlFor="pgContact" className="form-label">Contact Number</label>
												{/* <input type="text" id="pgContact" className="form-control" {...register('pgContact')} /> */}
												<Input className="mb-3 d-block" type="text" maxLength="10" placeholder="PG Contact" value={pgContact} set={setPgContact} onFocus={() => setPgContactError('')} validation={pgContactError} />
											</div>
										</div>
										<div className="form-group mb-0">
                                            <label htmlFor="pgContact" className="form-label">Floor</label>
                                            <div className="form-group form-check" style={{display: 'flex', flexWrap: 'wrap'}}>
                                                {
                                                    roomsArr.map((item, index) => {
                                                        return(
                                                            <div key={index} className="left-section" style={{padding: 20}}>
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id={`custom-checkbox-${index}`}
                                                                    name={item.name}
                                                                    value={item.value}
                                                                    checked={checkedState[item.name]}
                                                                    onChange={() => handleOnChange(item.name)}
                                                                    style={{cursor: 'pointer'}}
                                                                />
                                                                <label style={{cursor: 'pointer'}} className="form-check-label"  htmlFor={`custom-checkbox-${index}`}>{item.value}</label>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <p className="text-left p-1 text-danger mb-0">{floorError !== '' ? floorError : ''}</p>
                                        </div>
										<div className="form-row">
											<div className="form-group  mb-0 col">
												<label htmlFor="managersName" className="form-label">Owner/Property In charge</label>
												{/* <input type="text" id="managersName" className="form-control" {...register('managersName')} /> */}
												<select className="form-control" value={propertyOwner} onChange={(e) => {
													setPropertyOwner(e.target.value)
													setPropertyOwnerError('')
												}}>
													<option value=''>Select</option>
													{owner.ownerList && owner.ownerList.map((option) => (
														<option key={option._id} value={option._id}>{option.firstName + ' ' + option.lastName}</option>
													))}
												</select>
												<p className="text-left p-1 mb-1 text-danger">{propertyOwnerError !== '' ? propertyOwnerError : ''}</p>
											</div>
											<div className="form-group mb-0 col">
												<label htmlFor="availableFor">PG Available for</label>
												<select className="form-control" value={availableFor} onChange={(e) => {
													setAvailableFor(e.target.value)
													setAvailableForError('')
												}
												}>
													<option value=''>Select</option>
													<option value="onlyBoys">Only Boys</option>
													<option value="onlyGirls">Only Girls</option>
													<option value="any">Any</option>
												</select>
												<p className="text-left p-1 mb-1 text-danger">{availableForError !== '' ? availableForError : ''}</p>
											</div>
										</div>
										<div className="form-row">
											<div className="form-group mb-0 col">
												<label htmlFor="preferredTenants" className="form-label">Tenant Preferred</label>
												<select className="form-control" value={preferredTenants} onChange={(e) => {
													setPreferredTenants(e.target.value)
													setPreferredTenantsError('')
												}}>
													<option value=''>Select</option>
													<option value="student">Student</option>
													<option value="workingProfessional">Working Professional</option>
													<option value="both">Both</option>
												</select>
												<p className="text-left p-1 mb-1 text-danger">{preferredTenantsError !== '' ? preferredTenantsError : ''}</p>

											</div>
											<div className="form-group mb-0 col" />
										</div>
										<div className="form-row">
											<div className="form-group mb-0 col">
												<label htmlFor="addressLine1" className="form-label">Address 1</label>
												{/* <input type="text" id="addressLine1" className="form-control" {...register('addressLine1')} /> */}
												<Input className="mb-3 d-block" type="text" placeholder="PG Address 1" value={addressLine1} set={setAddressLine1} onFocus={() => setAddressLine1Error('')} validation={addressLine1Error} />
											</div>
											<div className="form-group  mb-0 col">
												<label htmlFor="addressLine2" className="form-label">Address 2</label>
												{/* <input type="text" id="addressLine2" className="form-control" {...register('addressLine2')} /> */}
												<Input className="mb-3 d-block" type="text" placeholder="PG Address 2" value={addressLine2} set={setAddressLine2} onFocus={() => setAddressLine2Error('')} validation={addressLine2Error} />
											</div>
										</div>
										<div className="form-row">
											<div className="form-group mb-0 col">
												<label htmlFor="city" className="form-label">City</label>
												{/* <input type="text" id="city" className="form-control" {...register('city')}/> */}
												<Input className="mb-3 d-block" type="text" placeholder="City" value={city} set={setCity} onFocus={() => setCityError('')} validation={cityError} />
											</div>
											<div className="form-group mb-0 col">
												<label htmlFor="state" className="form-label">State</label>
												{/* <input type="text" id="state" className="form-control" {...register('state')} /> */}
												<Input className="mb-3 d-block" type="text" placeholder="State" value={state} set={setState} onFocus={() => setStateError('')} validation={stateError} />
											</div>
										</div>
										<div className="form-row">
											<div className="form-group mb-0 col">
												<label htmlFor="propertyPincode" className="form-label">Pincode</label>
												{/* <input type="text" id="propertyPincode" className="form-control" {...register('propertyPincode')} /> */}
												<Input className="mb-3 d-block" type="number" placeholder="Pincode" value={propertyPincode} set={setPropertyPincode} onFocus={() => setPropertyPincodeError('')} validation={propertyPincodeError} />
											</div>
										</div>
										<div className="form-row d-flex justify-content-end">
											<button className="text-capitalize btn btn-info m-0" onClick={(e) => {
												e.preventDefault();
												onSubmit();
											}} type="submit">Submit</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		// </div>
	)
}

const Input = ({ className, placeholder, type, value, set, validation = '', maxLength, pattern, onFocus }) => {
	return (
		<>
			<div className={`form-group col${className}`}>
				<input type={type} pattern={pattern} className="form-control w-100" placeholder={placeholder} onFocus={onFocus} value={value} maxLength={maxLength} onChange={(e) => { set(e.target.value) }} />
				<p className="text-left p-1 text-danger mb-0">{validation !== '' ? validation : ''}</p>
			</div>
		</>
	)
}

export default PropertyList;