import axios from 'axios';

// Headers
export const axiosConfig = async () => {
    let authToken = await localStorage.getItem('userAuthToken');
    if (authToken) {
        return {
            headers: {
                Accept: 'application/x-www-form-urlencoded',
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            }
        };
    }
    else {
        return {
            headers: {
                Accept: 'application/x-www-form-urlencoded',
                'Content-Type': 'application/json',
            }
        };
    }
};
// Headers
export const axiosConfigImageUpload = async () => {
    // const authToken = await localStorage.getItem('userAuthToken');
    return {
      headers: {
        // Authorization: `Bearer ${authToken}`,
        'Content-Type': 'multipart/form-data',
      },
    };
  };
  
  // Post Call
  export const PostAPIImageUploadAxios = async (url, body) => {
    const config = await axiosConfigImageUpload();
    try {
        return axios.post(url, body, config);
    } catch (err) {
        return err;
    };
  };

// Get call
export const GetAPIAxios = async (url, paramsBody) => {
    let authToken = await localStorage.getItem('userAuthToken');
    const config = await axiosConfig();
    try {
        if (paramsBody) {
            return axios.get(url, {
                params: paramsBody, headers: {
                    Accept: 'application/x-www-form-urlencoded',
                    Authorization: `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                }
            });
        }
        else {
            return axios.get(url, config);
        }
    } catch (err) {
        return err;
    };
}

// Post Call
export const PostAPIAxios = async (url, body) => {
    const config = await axiosConfig();
    try {
        return axios.post(url, body, config);
    } catch (err) {
        return err;
    };
}

// Put Call
export const PutAPIAxios = async (url, body) => {
    const config = await axiosConfig();
    try {
        return axios.put(url, body, config);
    } catch (err) {
        return err;
    };
}

// Delete Call
export const DeleteAPIAxios = async (url) => {
    const config = await axiosConfig();
    try {
        return axios.delete(url, config);
    } catch (err) {
        return err;
    };
}