import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
// import { reducer as formReducer } from 'redux-form';
import loginReducer from './auth';
import sidebarReducer from './sidebar';
import ownerReducer from './owner';
import propertyReducer from './property';

export default history => combineReducers({
    router: connectRouter(history),
    login: loginReducer,
    sidebar: sidebarReducer,
    owner: ownerReducer,
    property: propertyReducer,
});