import { PROPERTY_FLAG, ROOMS_FLAG, OWNER_FLAG } from '../actions/actions';

const authDefaultState = {
    propertyListFlag: true,
    roomListFlag: true,
    ownerListFlag: true, 
};
export default function (state = authDefaultState, action) {
    switch (action.type) {
        case PROPERTY_FLAG:
            return { ...state, propertyListFlag: action.payload };
        case ROOMS_FLAG:
            return { ...state, roomListFlag: action.payload };
        case OWNER_FLAG:
            return { ...state, ownerListFlag: action.payload };
        default:
            return state;
    }
}