import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Signin from '../screens/signin/index';
import Dashboard from '../screens/dashboard/index';
import PropertyList from '../screens/property-list/propertyList';
import layoutWrapper from '../components/hoc/layoutWrapper';
// import noRequireAuth from '../Auth/protectedRoute';
// import requireAuth from '../Auth/requireAuth';
import ProtectedRoute from '../Auth/protectedRoute';
import RoomsList from '../screens/rooms-list/roomsList';
import OwnerList from '../screens/manage-owner/OwnerList';
import UnprotectedRoute from '../Auth/unprotectedRoute';

const AppRouter = () => (
    <div>
        <Switch>
            <UnprotectedRoute path="/signin" component={Signin} exact />
            <ProtectedRoute path="/" component={layoutWrapper(Dashboard)} exact />
            <ProtectedRoute path="/property-list" component={layoutWrapper(PropertyList)} exact />
            <ProtectedRoute path="/rooms-list" component={layoutWrapper(RoomsList)} exact />
            <ProtectedRoute path="/owner-list" component={layoutWrapper(OwnerList)} exact />
            <Route path='*' render={() => <Redirect to={{ pathname: "/" }} />} />
            {/* <Route path='*' component={UsersNotFound} /> */}
        </Switch>
    </div>
);

export default AppRouter;
