export const TEST_ACTION = 'TEST_ACTION';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const UN_AUTH = 'UN_AUTH';
export const AUTH_FAILURE = 'AUTH_FAILURE';
export const GET_INFO = 'GET_INFO';

// sidebar actions
export const PROPERTY_FLAG = 'PROPERTY_FLAG';
export const ROOMS_FLAG = 'ROOMS_FLAG';
export const OWNER_FLAG = 'OWNER_FLAG';

// Owner
export const OWNER_LIST = 'OWNER_LIST';

// Property
export const PROPERTY_LIST = 'PROPERTY_LIST';
export const SINGLE_PROPERTY = 'SINGLE_PROPERTY';
