import { AUTH_SUCCESS, UN_AUTH, AUTH_FAILURE, GET_INFO } from '../actions/actions';

const authDefaultState = {
    authenticated: false
};
export default function (state = authDefaultState, action) {
    switch (action.type) {
        case AUTH_SUCCESS:
            return { ...state, authenticated: true };
        case UN_AUTH:
            return { ...state, authenticated: false };
        case AUTH_FAILURE:
            return { ...state, error: action.payload };
        case GET_INFO:
            return { ...state, userInfo: action.payload };
        default:
            return state;
    }
}