import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link } from "react-router-dom";
import { GET_INFO } from '../../store/actions/actions';
import { getUserProfile } from '../../store/services/auth';

import './style.scss';

const SiteHead = () => {
    const [userName, setUserName] = useState('')

    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector(state => state.login)

    useEffect(() => {
        getProfile()
    }, [])

    const getProfile = async() => {
        try {
            let userRes = await getUserProfile();
            if (userRes) {
                dispatch({ type: GET_INFO, payload: user });

                setUserName(userRes.displayName)
            }
        } catch (error) {

        }
    }

    const logOut = async () => {
        await localStorage.clear();
        history.push('/signin')
    }

    return (
        <header className="navbar pcoded-header navbar-expand-lg navbar-light">
            <div className="m-header">
                <a className="mobile-menu" id="mobile-collapse1" href="javascript:"><span></span></a>
                <a href="index.html" className="b-brand">
                    <div className="b-bg">
                        <i className="feather icon-trending-up"></i>
                    </div>
                    <span className="b-title">Datta Able</span>
                </a>
            </div>
            <a className="mobile-menu" id="mobile-header" href="javascript:">
                <i className="feather icon-more-horizontal"></i>
            </a>
            <div className="collapse navbar-collapse" style={{ background: '#3f4d6770' }}>
                <ul className="navbar-nav ml-auto">
                    {/* <li>
                        <div className="dropdown">
                            <a className="dropdown-toggle" href="javascript:" data-toggle="dropdown"><i className="icon feather icon-bell"></i></a>
                            <div className="dropdown-menu dropdown-menu-right notification">
                                <div className="noti-head">
                                    <h6 className="d-inline-block m-b-0">Notifications</h6>
                                    <div className="float-right">
                                        <a href="javascript:" className="m-r-10">mark as read</a>
                                        <a href="javascript:">clear all</a>
                                    </div>
                                </div>
                                <ul className="noti-body">
                                    <li className="n-title">
                                        <p className="m-b-0">NEW</p>
                                    </li>
                                    <li className="notification">
                                        <div className="media">
                                            <img className="img-radius" src="assets/images/user/avatar-1.jpg" alt="Generic placeholder image" />
                                            <div className="media-body">
                                                <p><strong>John Doe</strong><span className="n-time text-muted"><i className="icon feather icon-clock m-r-10"></i>30 min</span></p>
                                                <p>New ticket Added</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="n-title">
                                        <p className="m-b-0">EARLIER</p>
                                    </li>
                                    <li className="notification">
                                        <div className="media">
                                            <img className="img-radius" src="assets/images/user/avatar-2.jpg" alt="Generic placeholder image" />
                                            <div className="media-body">
                                                <p><strong>Joseph William</strong><span className="n-time text-muted"><i className="icon feather icon-clock m-r-10"></i>30 min</span></p>
                                                <p>Prchace New Theme and make payment</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="notification">
                                        <div className="media">
                                            <img className="img-radius" src="assets/images/user/avatar-3.jpg" alt="Generic placeholder image" />
                                            <div className="media-body">
                                                <p><strong>Sara Soudein</strong><span className="n-time text-muted"><i className="icon feather icon-clock m-r-10"></i>30 min</span></p>
                                                <p>currently login</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <div className="noti-footer">
                                    <a href="javascript:">show all</a>
                                </div>
                            </div>
                        </div>
                    </li> */}
                    <li>
                        <div className="dropdown drp-user">
                            <a href="javascript:" className="dropdown-toggle" data-toggle="dropdown">
                                <i className="icon feather icon-settings"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right profile-notification">
                                <div className="pro-head">
                                    {/* <img src="assets/images/user/avatar-1.jpg" className="img-radius" alt="User-Profile-Image" /> */}
                                    <span>{userName}</span>
                                    {/* <a href="auth-signin.html" className="dud-logout" title="Logout">
                                        <i className="feather icon-log-out"></i>
                                    </a> */}
                                </div>
                                <ul className="pro-body">
                                    <li><a href="javascript:" className="dropdown-item d-flex justify-content-between" onClick={() => logOut()}>Logout<i className="feather icon-log-out"></i></a></li>
                                    {/* <li><a href="javascript:" className="dropdown-item"><i className="feather icon-user"></i> Profile</a></li>
                                    <li><a href="message.html" className="dropdown-item"><i className="feather icon-mail"></i> My Messages</a></li>
                                    <li><a href="auth-signin.html" className="dropdown-item"><i className="feather icon-lock"></i> Lock Screen</a></li> */}
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </header>
    );
}

export default SiteHead;
