import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useHistory, Link } from "react-router-dom";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import TableActionButtton from '../../components/hoc/tableActionButtton';
import Loader from "react-loader-spinner";
import { addNewOwner, deleteSingleOwner, editSingleOwner, getOwner } from '../../store/services/owner';
import Toast from '../../components/toast/Toast';
import {useSelector, useDispatch} from 'react-redux';
import { OWNER_LIST } from '../../store/actions/actions';
import ModalWrapper from '../../components/hoc/modalWrapper';

function OwnerList() {
    let history = useHistory();
    const [showOwnerList, setShowOwnerList] = useState(true)
    const [products, setProducts] = useState([]);
    const [modal, setModal] = useState(false);
    const [list, setList] = useState([]);
    const [edit, setEdit] = useState(false);
    const [singleOwner, setSingleOwner] = useState({});
    const [loader, setLoader] = useState(true);
    const [showDeleteModal, setShowDeleteModal] = useState(false);


    const dispatch = useDispatch();

    const sidebar = useSelector(state => state.sidebar);

    const columns = [
        {
            dataField: 'firstName',
            text: 'PG Owner Name',
            formatter: (cell, row) => {
                return <div>{`${row.firstName} ${row.lastName}`}</div>;
            }
        },
        {
            dataField: 'email',
            text: 'PG Owner Email'
        },
        // {
        //     dataField: 'mobile',
        //     text: 'Pg Owner Contact'
        // }, 
        {
            dataField: 'action',
            text: 'Action',
        }];

    let ActonButtonTooltips = ["", "Edit owner", "Delete owner"];

    useEffect(() => {
        getOwnerList();

        return () => {
            setEdit(false)
        }
    }, [sidebar]);

    useEffect(() => {
        setShowOwnerList(true);
    }, [sidebar]);

    const getOwnerList = async () => {
        try {
            let response = await getOwner();
            if (response) {
                dispatch({ type: OWNER_LIST, payload: response })
                let newList = response;
                newList.forEach((element) => {
                    element.action = (
                        <TableActionButtton
                            deleteFn={ownerDelete}
                            editFn={ownerEdit}
                            buttonTooltips={ActonButtonTooltips}
                            element={element}
                        />
                    );
                });
                setProducts(newList)
                setLoader(false)
            }
        } catch (err) {
            setLoader(false)
            if (err.message) {
                errorToast(err.message)
            }
        }
    }

    const errorToast = (error) => {
        let toastProperties = {
            id: 0,
            title: 'Error!',
            description: error,
            backgroundColor: '#d9534f',
            // icon: errorIcon
        }
        setList([...list, toastProperties]);
    }

    const successToast = (success) => {
        let toastProperties = {
            id: 0,
            title: 'Success!',
            description: success,
            backgroundColor: '#34A853',
            // icon: errorIcon
        }
        setList([...list, toastProperties]);
    }

    const updateTable = (element, id = '') => {
        let oldList = [...products];
        if (id === "") {
            oldList = [...oldList, element]
            oldList.forEach((element) => {
                element.action = (
                    <TableActionButtton
                        deleteFn={ownerDelete}
                        editFn={ownerEdit}
                        buttonTooltips={ActonButtonTooltips}
                        element={element}
                    />
                );
            });
            setProducts(oldList);
        } else {
            var foundIndex = oldList.findIndex(x => x._id == id);
            element.action = (
                <TableActionButtton
                    deleteFn={ownerDelete}
                    editFn={ownerEdit}
                    buttonTooltips={ActonButtonTooltips}
                    element={element}
                />
            );
            oldList[foundIndex] = element;
            setProducts(oldList);
        }
    }


    const ownerDelete = (ele) => {
        setSingleOwner(ele);
        setShowDeleteModal(true);
    };
    const ownerEdit = (ele) => {
        setEdit(true);
        setSingleOwner(ele);
        setShowOwnerList(false);
    }

    const onDeleteHandler = async (id) => {
        try {
            const deleteResp = await deleteSingleOwner(id);
            if(deleteResp){
                let tempList = products;
                tempList = tempList.filter(tL => tL._id != id);
                setProducts(tempList);
                setShowDeleteModal(false);
                successToast('Owner Deleted Successfully.');
            }
        } catch (err) {
            if (err.message) {
                errorToast(err.message)
            }
        }
    }
    const closeModal = () => {
        setShowDeleteModal(false);
    }

    return (
        <>
            <Toast
                toastList={list}
                position={"top-right"}
                autoDelete={true}
                dismissTime={3000}
            />
            <div className="pcoded-main-container">
                <ModalWrapper
                    header="Delete"
                    title="Are you sure you want to delete this user?"
                    show={showDeleteModal}
                    element={singleOwner}
                    onDeleteHandler={onDeleteHandler}
                    closeModal={closeModal}
                />
                <div className="card p-3 mb-0">
                    <div className="d-flex justify-content-between align-items-center">
                        {/* <h6 className="m-0">{!showOwnerList ? `Add PG Owner` : `PG Owner List`}</h6> */}

                        {
                            !edit ?
                                <h6 className="m-0">{(showOwnerList) ? `PG Owner List` : `Add PG Owner`}</h6>
                                :
                                <h6 className="m-0">{(!showOwnerList && edit) ? `Edit PG Owner` : ``}</h6>
                        }
                        <div className="">
                            <button className="text-capitalize btn btn-info m-0" onClick={() => {
                                setShowOwnerList(!showOwnerList)
                                setEdit(false)
                            }}><i className="feather icon-plus"></i>{showOwnerList ? `Add PG Owner` : `PG Owner List`}</button>
                        </div>
                    </div>
                </div>
                {showOwnerList ?
                    <div className="pcoded-wrapper">
                        <div className="pcoded-content">
                            <div className="pcoded-inner-content">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className={!loader ? "card" : ''}>
                                            {/* <div className="card-body">
                                                <BootstrapTable keyField='id' data={products} columns={columns} />
                                            </div> */}
                                            {
                                                !loader ?
                                                    <>
                                                        <div className="card-body">
                                                            <BootstrapTable keyField='id' data={products} columns={columns} />
                                                        </div>
                                                        {
                                                            products.length <= 0 ?
                                                                <div className="card-body text-center pt-0">
                                                                    <span className="font-weight-bold h6">No owner found.</span>
                                                                </div> : null
                                                        }
                                                    </>
                                                    :
                                                    <div className="text-center">
                                                        <Loader
                                                            type="Puff"
                                                            color="#00BFFF"
                                                            height={30}
                                                            width={100}
                                                        />
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> :
                    <OwnerAddEdit showOwnerList={showOwnerList} setShowOwnerList={setShowOwnerList} updateTable={updateTable} errorToast={errorToast} edit={edit} singleOwnerData={singleOwner} successToast={successToast} />
                }
            </div>
        </>
    );
}

const OwnerAddEdit = ({ showOwnerList, setShowOwnerList, updateTable, errorToast, edit = false, singleOwnerData, successToast }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('')

    const emailValidator = (value) =>
        value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
            ? true
            : false;

    const phoneValidator = (value) =>
        value && !/^[0-9]+$/i.test(value)
            ? true
            : false;

    useEffect(() => {
        updateForm();
    }, [edit]);


    const updateForm = () => {
        if (edit) {
            setFirstName(singleOwnerData.firstName);
            setLastName(singleOwnerData.lastName);
            setEmail(singleOwnerData.email)
            setPhoneNumber(singleOwnerData.mobile);
        }
    }

    const validate = () => {
        let emailError = '';
        let passwordError = '';
        let firstNameError = '';
        let lastNameError = '';
        let phoneNumberError = '';
        let confirmPasswordError = '';

        if (firstName === '') {
            firstNameError = "First name is required"
        }
        if (lastName === '') {
            lastNameError = "Last name is required"
        }
        if (email === '') {
            emailError = 'Email is required.';
        }
        if (emailValidator(email)) {
            emailError = 'Please enter a valid email address';
        }
        if (phoneNumber === '') {
            phoneNumberError = 'Phone number  is required';
        }
        if (phoneValidator(phoneNumber)) {
            phoneNumberError = 'Phone number must contain only digits';
        }
        if (!phoneValidator(phoneNumber) && (phoneNumber.length < 10 || phoneNumber.length > 10)) {
            phoneNumberError = 'Phone number must be of 10 digits';
        }
        if (password === '') {
            passwordError = 'Password is required';
        }
        if (password != '' && password.length < 6) {
            passwordError = 'Password must be of minimum 6 characters';
        }
        if (confirmPassword === '') {
            confirmPasswordError = "Confirm password is required"
        }
        if (confirmPassword != password) {
            confirmPasswordError = 'Confirm password does not match'
        }
        if (edit) {
            if (emailError || firstNameError || lastNameError || phoneNumberError) {
                setEmailError(emailError);
                setFirstNameError(firstNameError);
                setLastNameError(lastNameError);
                setPhoneNumberError(phoneNumberError);
                return false;
            }
            return true;
        } else {
            if (emailError || passwordError || firstNameError || lastNameError || phoneNumberError || confirmPasswordError) {
                setEmailError(emailError);
                setPasswordError(passwordError);
                setFirstNameError(firstNameError);
                setLastNameError(lastNameError);
                setPhoneNumberError(phoneNumberError);
                setConfirmPasswordError(confirmPasswordError);
                return false;
            }
            return true;
        }
    };

    const onSubmit = async () => {
        if (!edit) {
            try {
                const isValid = validate();
                if (isValid) {
                    await clearError();
                    if (password !== confirmPassword) {
                        errorToast("Confirm password does not match");
                        return
                    }
                    const data = {
                        firstName: firstName,
                        lastName: lastName,
                        mobile: phoneNumber,
                        email: email,
                        password: password,
                        confirmPassword: confirmPassword,
                        roles: "owner"
                    }
                    const response = await addNewOwner(data);
                    if (response) {
                        setShowOwnerList(true);
                        updateTable(response);
                        successToast('PG Owner added Successfully.');
                    }
                }
            } catch (err) {
                if (err.message) {
                    errorToast(err.message);
                }
            }
        } else {
            onSubmitEdit();
        }

    }

    const onSubmitEdit = async () => {
        try {
            const isValid = validate();
            if (isValid) {
                await clearError();
                const data = {
                    firstName: firstName,
                    lastName: lastName,
                    mobile: phoneNumber,
                    email: email,
                    roles: "owner"
                }
                const response = await editSingleOwner(singleOwnerData._id, data);
                if (response) {
                    setShowOwnerList(true);
                    updateTable(response, singleOwnerData._id);
                    successToast('PG Owner Edited Successfully.');
                }
            }
        } catch (err) {
            if (err.message) {
                errorToast(err.message);
            }
        }
    }

    const clearError = () => {
        setEmailError('');
        setPasswordError('');
        setFirstNameError('');
        setLastNameError('');
        setPhoneNumberError('');
        setConfirmPasswordError('');
    }

    return (
        <div className="pcoded-wrapper">
            <div className="pcoded-content">
                <div className="pcoded-inner-content">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <form>
                                        <div className="form-group col">
                                            <label htmlFor="firstName" className="form-label">First Name</label>
                                            {/* <input type="text" id="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)} className="form-control" /> */}
                                            <Input className="mb-3 d-block" type="text" maxLength="15" placeholder="First name" value={firstName} set={setFirstName} onFocus={() => setFirstNameError('')} validation={firstNameError} />

                                        </div>
                                        <div className="form-group col">
                                            <label htmlFor="lastName" className="form-label">Last Name</label>
                                            {/* <input type="text" id="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)} className="form-control" /> */}
                                            <Input className="mb-3 d-block" type="text" maxLength="15" placeholder="Last name" value={lastName} set={setLastName} onFocus={() =>setLastNameError('')} validation={lastNameError} />

                                        </div>
                                        <div className="form-group col">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <Input className="mb-3 d-block" type="email" placeholder="Email" value={email} set={setEmail} onFocus={() => setEmailError('')}  validation={emailError} />
                                        </div>
                                        <div className="form-group col">
                                            <label htmlFor="phoneNumber" className="form-label">PG Owner Phone No.</label>
                                            {/* <input type="number" id="phoneNumber" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} className="form-control" /> */}
                                            <Input className="mb-4 d-block" type="text" maxLength="10" placeholder="Phone number" value={phoneNumber} set={setPhoneNumber} onFocus={() => setPhoneNumberError('')} validation={phoneNumberError} />
                                        </div>
                                        {
                                            !edit ?
                                                <>
                                                    <div className="form-group col">
                                                        <label htmlFor="password" className="form-label">Password</label>
                                                        {/* <input type="text" id="password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" /> */}
                                                        <Input className="mb-4 d-block" type="password" placeholder="Password" value={password} set={setPassword} onFocus={() => setPasswordError('')} validation={passwordError} />

                                                    </div>
                                                    <div className="form-group col">
                                                        <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                                                        {/* <input type="text" id="confirmPassword" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} className="form-control" /> */}
                                                        <Input className="mb-4 d-block" type="password" placeholder="Confirm password" value={confirmPassword} set={setConfirmPassword} onFocus={() => setConfirmPasswordError('')} validation={confirmPasswordError} />

                                                    </div>
                                                </>
                                                :
                                                null
                                        }

                                        <div className="form-row d-flex justify-content-end">
                                            <button className="text-capitalize btn btn-info m-0" onClick={(event) => {
                                                event.preventDefault();
                                                onSubmit();
                                            }}>Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Input = ({ className, placeholder, type, value, set, validation = '', maxLength, pattern, onFocus }) => {
    return (
        <>
            <div className={`form-group col${className}`}>
                <input type={type} pattern={pattern} className="form-control w-100" onFocus={onFocus} placeholder={placeholder} value={value} maxLength={maxLength} onChange={(e) => { set(e.target.value) }} />
                <p className="text-left p-1 text-danger">{validation !== '' ? validation : ''}</p>
            </div>
        </>
    )
}

export default OwnerList;