import {DeleteAPIAxios, GetAPIAxios, PostAPIAxios, PutAPIAxios} from './service';

const API_URL = env.API_URL;

export const addNewProperty = async (body) => {
  let url = `${API_URL}/property`;
  try {
    const response = await PostAPIAxios(url, body);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const updateProperty = async (body, id) => {
	let url = `${API_URL}/property/${id}`;
	try {
	  const response = await PutAPIAxios(url, body);
	  return response.data;
	} catch (error) {
	  throw new Error(error.response.data.message);
	}
};

export const getPropertyList = async () => {
	let url = `${API_URL}/property`;
	try {
	  const response = await GetAPIAxios(url, {});
	  return response.data;
	} catch (error) {
	  throw new Error(error.response.data.message);
	}
};

// Get single property

export const getSingleProperty = async (id) => {
	let url = `${API_URL}/property/${id}`;
	try {
	  const response = await GetAPIAxios(url, {});
	  return response.data;
	} catch (error) {
	  throw new Error(error.response.data.message);
	}
};

export const deleteSingleProperty = async (id) => {
	let url = `${API_URL}/property/${id}`;
	try {
	  const response = await DeleteAPIAxios(url, {});
	  return response.data;
	} catch (error) {
	  throw new Error(error.response.data.message);
	}
};
