import {GetAPIAxios, PostAPIAxios} from './service';
const API_URL = env.API_URL;

export const signIn = async (body) => {
  let url = `${API_URL}/auth/signin`;
  try {
    const response = await PostAPIAxios(url, body);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getUserProfile = async () => {
  let url = `${API_URL}/users/me`;
  try {
    const response = await GetAPIAxios(url);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};