import React, {useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { useSelector, useDispatch } from 'react-redux';
import { OWNER_LIST } from '../../store/actions/actions';
import { getPropertyList } from '../../store/services/property';
import { getRoomList } from '../../store/services/room';

function Dashboard() {
	const [list, setList] = useState([]);
	const [totalPg, setTotalPg] = useState(0);
	const [totalRooms, setTotalRooms] = useState(0);

	let history = useHistory();
	const dispatch = useDispatch();

	useEffect(() => {
		loadDashProperty();
		loadDashRooms()
	}, []);
	
	const loadDashProperty = async() => {
		try {
			let response = await getPropertyList();
			if (response.length > 0) {
				setTotalPg(response.length);
			}
		} catch (err) {
			if (err.message) {
				errorToast(err.message)
			}
		}
	}

	const loadDashRooms = async() => {
		try {
			let response = await getRoomList();
			if (response.length > 0) {
				setTotalRooms(response.length);
			}
		} catch (err) {
			if (err.message) {
				errorToast(err.message)
			}
		}
	}

	const errorToast = (error) => {
		let toastProperties = {
			id: 0,
			title: 'Error!',
			description: error,
			backgroundColor: '#d9534f',
			// icon: errorIcon
		}
		setList([...list, toastProperties]);
	}

	return (
		<div className="pcoded-main-container">
			<div className="pcoded-wrapper">
				<div className="pcoded-content">
					<div className="pcoded-inner-content">
						<div className="row">
							<div className="col-lg-4 col-md-4 ">
								<div className="card">
									<div className="card-body">
										<h6 className="mb-2">Total PG</h6>
										<div className="row">
											<div className="col-9">
												<h3>{totalPg}</h3>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-4 ">
								<div className="card">
									<div className="card-body">
										<h6 className="mb-2">Total ROOMS</h6>
										<div className="row">
											<div className="col-9">
												<h3>{totalRooms}</h3>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-4 ">
								<div className="card">
									<div className="card-body">
										<h6 className="mb-2">Total TENANTS</h6>
										<div className="row">
											<div className="col-9">
												<h3>0</h3>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Dashboard;