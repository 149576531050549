import React from 'react';
import PropTypes from 'prop-types';
import NavList from './NavList';

import './style.scss';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import { PROPERTY_FLAG, OWNER_FLAG, ROOMS_FLAG } from '../../store/actions/actions';

const Nav = props => { 
    let history = useHistory();
    let location = useLocation();
    const dispatch = useDispatch();
    return (
    <nav className="pcoded-navbar">
        <div className="navbar-wrapper">
            <div className="navbar-brand header-logo">
            <a className="b-brand">
                <div className="b-bg">
                    <i className="feather icon-trending-up"></i>
                </div>
                <span className="b-title">Prime PG</span>
            </a>
            <a className="mobile-menu d-none" id="mobile-collapse" href="javascript:"><span></span></a>
        </div>
            <div className="navbar-content scroll-div">
                <ul className="nav pcoded-inner-navbar">
                    <li className="nav-item pcoded-menu-caption">
                        <label>Navigation</label>
                    </li>
                    <li data-username="dashboard Default Ecommerce CRM Analytics Crypto Project" className={`${location.pathname === '/' ? 'nav-item active' : 'nav-item'}`}>
                        <Link className="nav-link " onClick={() => history.push('/')}><span className="pcoded-micon"><i className="feather icon-grid"></i></span><span className="pcoded-mtext">Dashboard</span></Link>
                    </li>
                    <li data-username="dashboard Default Ecommerce CRM Analytics Crypto Project"  className={`${location.pathname === '/owner-list' ? 'nav-item active' : 'nav-item'}`}>
                        <Link className="nav-link " onClick={() =>{ 
                            history.push('/owner-list')
                            dispatch({type:OWNER_FLAG, payload: true })
                        }}><span className="pcoded-micon"><i className="feather icon-user"></i></span><span className="pcoded-mtext">Manage PG Owner</span></Link>
                    </li>
                    <li data-username="dashboard Default Ecommerce CRM Analytics Crypto Project"  className={`${location.pathname === '/property-list' || location.pathname === '/rooms-list' ? 'nav-item active' : 'nav-item'}`}>
                        <Link className="nav-link " onClick={() => {
                                history.push('/property-list')
                                dispatch({type:PROPERTY_FLAG, payload: true })
                            }}><span className="pcoded-micon"><i className="feather icon-home"></i></span><span className="pcoded-mtext">Property List</span></Link>
                    </li>
                    {/* <li data-username="dashboard Default Ecommerce CRM Analytics Crypto Project" className="nav-item">
                        <Link className="nav-link " onClick={() => {
                                history.push('/rooms-list')
                                dispatch({type:ROOMS_FLAG, payload: true })
                            }}><span className="pcoded-micon"><i className="feather icon-grid"></i></span><span className="pcoded-mtext">Rooms List</span></Link>
                    </li> */}
                </ul>
            </div>
        </div>
    </nav>)
};

Nav.propTypes = {
    // mini: PropTypes.bool.isRequired,
    // dispatch: PropTypes.func.isRequired
};

export default Nav;
