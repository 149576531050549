import {DeleteAPIAxios, GetAPIAxios, PostAPIAxios, PutAPIAxios} from './service';

const API_URL = env.API_URL;

export const addNewRoom = async (body) => {
  let url = `${API_URL}/room`;
  try {
    const response = await PostAPIAxios(url, body);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const updateRoom = async (body, id) => {
	let url = `${API_URL}/room/${id}`;
	try {
	  const response = await PutAPIAxios(url, body);
	  return response.data;
	} catch (error) {
	  throw new Error(error.response.data.message);
	}
};

export const getRoomList = async () => {
	let url = `${API_URL}/room`;
	try {
	  const response = await GetAPIAxios(url);
	  return response.data;
	} catch (error) {
	  throw new Error(error.response.data.message);
	}
};

export const getRoomByProperty = async (id) => {
	let url = `${API_URL}/rooms-by-property/${id}`;
	try {
	  const response = await GetAPIAxios(url);
	  return response.data;
	} catch (error) {
	  throw new Error(error.response.data.message);
	}
};

export const deleteSingleRoom = async (id) => {
	let url = `${API_URL}/room/${id}`;
	try {
	  const response = await DeleteAPIAxios(url);
	  return response.data;
	} catch (error) {
	  throw new Error(error.response.data.message);
	}
};
