import React, { Fragment } from "react";
import ReactTooltip from "react-tooltip";

const TableActionButtton = ({ deleteFn, editFn, routeToRoomFn, buttonTooltips, element }) => {
    return (
        <Fragment>
            {
                routeToRoomFn ?
                    <>
                        <button onClick={(e) => {
                            e.preventDefault()
                            routeToRoomFn(element)
                        }} className="btn-icon btn btn-secondary align-center"
                            style={{ paddingRight: 35, paddingBottom: 35 }}
                            data-tip data-for="registerTip1"
                        >
                            <img src="images/property.png" style={{ height: 28, width: 28, margin: -3 }} className="h-30 w-30" alt="property-image" />
                        </button>
                        <ReactTooltip id="registerTip1" place="top" effect="solid">
                            {buttonTooltips[0]}
                        </ReactTooltip>
                    </>
                    : null
            }
            <>
                <button onClick={(e) => {
                    e.preventDefault()
                    editFn(element)
                }} className="btn-icon btn btn-info"
                data-tip data-for="registerTip2"
                ><i className="feather icon-edit"></i>
                </button>
                <ReactTooltip id="registerTip2" place="top" effect="solid">
                    {buttonTooltips[1]}
                </ReactTooltip>
            </>
            <>
                <button onClick={(e) => {
                        e.preventDefault()
                        deleteFn(element)
                    }} className="btn-icon btn btn-danger"
                    data-tip data-for="registerTip3"
                    >
                        <i className="feather icon-trash"></i>
                </button>
                <ReactTooltip id="registerTip3" place="top" effect="solid">
                    {buttonTooltips[2]}
                </ReactTooltip>
            </>
        </Fragment>
    );
};
export default TableActionButtton;