import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import configureStore, { history } from './store/configureStore';
import AppRouter from './routers/AppRouter';
import './styles/styles.scss';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

// Define store
const store = configureStore();
const App = () => (
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <AppRouter />
        </ConnectedRouter>
    </Provider>
);
export default App;

ReactDOM.render(<App />, document.getElementById('app'));
