import React, { useEffect, useState } from 'react';
import { useHistory, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';
import { AUTH_SUCCESS, GET_INFO } from '../../store/actions/actions';
import { getUserProfile, signIn } from '../../store/services/auth';
import Toast from '../../components/toast/Toast';

function Signin() {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [list, setList] = useState([])
    let history = useHistory();
    const dispatch = useDispatch()

    const onSubmit = async () => {
        try {
            const isValid = validate();
            if (isValid) {
                const response = await signIn({ email, password });
                if (response) {
                    await localStorage.setItem("userAuthToken", response.token);
                    let user = await getUserProfile();
                    if (user) {
                        dispatch({ type: AUTH_SUCCESS });
                        dispatch({ type: GET_INFO, payload: user });
                        history.push('/');
                    }
                }
            }
        } catch (err) {
            if (err.message) {
                let toastProperties = {
                    id: 0,
                    title: 'Error!',
                    description: err.message,
                    backgroundColor: '#d9534f',
                    // icon: errorIcon
                }
                setList([...list, toastProperties]);
            }
        }
    }

    const emailValidator = (value) =>
        value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
            ? 'Invalid email address.'
            : undefined;
    const validate = () => {
        let emailError = '';
        let passwordError = '';
        if (email === '') {
            emailError = 'Email is required.';
        }
        if (emailValidator(email)) {
            emailError = 'Please Enter a Valid Email Address';
        }
        if (password === '') {
            passwordError = 'Password is required';
        }
        if (emailError || passwordError) {
            setEmailError(emailError);
            setPasswordError(passwordError);
            return false;
        }
        return true;
    };
    return (
        <>
            <Toast
                toastList={list}
                position={"top-right"}
                autoDelete={true}
                dismissTime={3000}
            />
            <div className="auth-wrapper">
                <div className="auth-content">
                    <div className="auth-bg">
                        <span className="r"></span>
                        <span className="r s"></span>
                        <span className="r s"></span>
                        <span className="r"></span>
                    </div>
                    <div className="card">
                        <div className="card-body text-center">
                            <form>
                                <div className="mb-4">
                                    <i className="feather icon-unlock auth-icon"></i>
                                </div>
                                <h3 className="mb-4">Login</h3>
                                {/*   */}
                                <Input className="mb-3 d-block" type="email" placeholder="Email" value={email} set={setEmail} validation={emailError} onFocus={() => setEmailError('')} />
                                <Input className="mb-4 d-block" type="password" placeholder="password" value={password} set={setPassword} validation={passwordError} onFocus={() => setPasswordError('')} />
                                {/*  */}
                                <button className="btn btn-primary shadow-2 mb-4" onClick={(event) => { event.preventDefault(), onSubmit() }} >Login</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const Input = ({ className, placeholder, type, value, set, validation = '', onFocus }) => {
    return (
        <>
            <div className={`input-group ${className}`}>
                <input type={type} className="form-control w-100" placeholder={placeholder} value={value} onChange={(e) => { set(e.target.value)}} onFocus={onFocus} />
                <p className="text-left p-1 text-danger">{validation != '' ? validation : ''}</p>
            </div>
        </>
    )
}

export default Signin;
