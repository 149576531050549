import {DeleteAPIAxios, GetAPIAxios, PostAPIAxios, PutAPIAxios} from './service';
const API_URL = env.API_URL;

export const addNewOwner = async (body) => {
  let url = `${API_URL}/auth/add-user`;
  try {
    const response = await PostAPIAxios(url, body);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getOwner = async () => {
    let url = `${API_URL}/users/owners`;
    try {
      const response = await GetAPIAxios(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
};

export const getSingleOwner = async (id) => {
    let url = `${API_URL}/users/${id}`;
    try {
      const response = await GetAPIAxios(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
};

export const editSingleOwner = async (id, body) => {
    let url = `${API_URL}/users/${id}`;
    try {
      const response = await PutAPIAxios(url, body);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
};


export const deleteSingleOwner = async (id) => {
	let url = `${API_URL}/users/${id}`;
	try {
	  const response = await DeleteAPIAxios(url);
	  return response.data;
	} catch (error) {
	  throw new Error(error.response.data.message);
	}
};
