import React, { useEffect, useState } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import TableActionButtton from '../../components/hoc/tableActionButtton';
import { deleteSingleProperty, getPropertyList, getSingleProperty } from '../../store/services/property';
import ModalWrapper from '../../components/hoc/modalWrapper';
import Loader from "react-loader-spinner";
import { useSelector, useDispatch } from 'react-redux';
import Toast from '../../components/toast/Toast';
import { addNewRoom, deleteSingleRoom, getRoomByProperty, getRoomList, updateRoom } from '../../store/services/room';
import { PROPERTY_LIST, SINGLE_PROPERTY } from '../../store/actions/actions';
import { useHistory, useLocation } from "react-router-dom";

function RoomsList() {
    const [showRoomList, setShowRoomList] = useState(true)
    const [roomsList, setRoomsList] = useState([]);
    const [edit, setEdit] = useState(false);
    // const [singleProperty, setSingleProperty] = useState({});
    const [singleRoom, setSingleRoom] = useState({});
    const [propertyList, setPropertyList] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [loader, setLoader] = useState(true);
    const [list, setList] = useState([]);

    const dispatch = useDispatch();
    const history = useHistory();

    const sidebar = useSelector(state => state.sidebar);

    const owner = useSelector(state => state.owner);
    const location = useLocation();

    const columns = [
        {
            dataField: 'floor',
            text: 'Floor',
            formatter: (cell, row) => {

                let abc = row.floor.replace('Floor', ' Floor').split(' ');
                let finalString = '';
                abc.forEach((str) => {
                    finalString = finalString + ' ' + str.replace(
                        /\w\S*/g,
                        function (txt) {
                            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                        }
                    );
                })
                // let finalString=
                return <div>{`${finalString}`}</div>;
            }
        },
        {
            dataField: 'roomNo',
            text: 'Room Number',
            formatter: (cell, row) => {
                return <div>{`${row.roomNo.toString()}`}</div>;
            }
        },
        {
            dataField: 'rentAmount',
            text: 'Rent Amount'
        },
        {
            dataField: '',
            text: 'Occupancy',
            formatter: (cell, row) => {
                if(row.bed){
                    const result = Object.keys(row.bed).filter((d) => d.includes('bed'));
                    const newResult = result.filter((r) => row.bed[r].activated == true).length
                    return <div>{newResult}</div>
                } else {
                    return <div>0</div>
                }
               
            }
        },
        {
            dataField: 'action',
            text: 'Action',
        }];

        let ActonButtonTooltips=["","Edit room","Delete room"];

    useEffect(async () => {
        getRoom()
    }, []);

    const getRoom = async () => {
        getProperty()
        let roomTempList = [];
        setLoader(true);
        const response = await getRoomByProperty(location.state.propertyId);
        if (response.length >= 0) {
            setLoader(false);
            roomTempList = response;
            if (roomTempList.length > 0) {
                roomTempList.forEach((element) => {
                    element.action = (
                        <TableActionButtton deleteFn={deleteRoom} editFn={editRoom} buttonTooltips={ActonButtonTooltips} element={element} />
                    );
                });
            }
            setRoomsList(roomTempList);
        }
        return () => {
            setEdit(false)
        }
    }

    useEffect(() => {
        setShowRoomList(true);
    }, [sidebar]);

    const getProperty = async () => {
        try {
            let response = await getPropertyList();
            if (response) {
                dispatch({ type: PROPERTY_LIST, payload: response });
                let findProperty = response.find(x => x._id === location.state.propertyId);
                if (findProperty) {
                    dispatch({ type: SINGLE_PROPERTY, payload: findProperty });
                }


            }
        } catch (err) {
            if (err.message) {
                errorToast(err.message)
            }
        }
    }

    const updateTable = (element, id = '') => {
        let oldList = [...roomsList];
        if (id === "") {
            oldList = [...oldList]
            oldList.unshift(element);
            oldList.forEach((element) => {
                element.action = (
                    <TableActionButtton
                        deleteFn={deleteRoom}
                        editFn={editRoom}
                        buttonTooltips={ActonButtonTooltips}
                        element={element}
                    />
                );
            });
            setRoomsList(oldList);
        } else {
            var foundIndex = oldList.findIndex(x => x._id == id);
            element.action = (
                <TableActionButtton
                    deleteFn={deleteRoom}
                    editFn={editRoom}
                    buttonTooltips={ActonButtonTooltips}
                    element={element}
                />
            );
            oldList[foundIndex] = element;

            setRoomsList(oldList);
        }
    }

    const errorToast = (error) => {
        let toastProperties = {
            id: 0,
            title: 'Error!',
            description: error,
            backgroundColor: '#d9534f',
            // icon: errorIcon
        }
        setList([...list, toastProperties]);
    }

    const successToast = (success) => {
        let toastProperties = {
            id: 0,
            title: 'Success!',
            description: success,
            backgroundColor: '#34A853',
            // icon: errorIcon
        }
        setList([...list, toastProperties]);
    }

    const editRoom = (room) => {
        setSingleRoom(room);
        setShowRoomList(false);
        setEdit(true);
    }

    const deleteRoom = (room) => {
        setSingleRoom(room);
        setShowDeleteModal(true);
    }

    const onDeleteHandler = async (id) => {
        try {
            const deleteResp = await deleteSingleRoom(id);
            if(deleteResp){
                let tempList = roomsList;
                tempList = tempList.filter(tL => tL._id != id);
                setRoomsList(tempList);
                setShowDeleteModal(false);
                successToast('Room Deleted Successfully.');    
            }
        } catch (err) {
            if (err.message) {
                errorToast(err.message)
            }
        }
    }

    const closeModal = () => {
        setShowDeleteModal(false);
    }

    return (
        <div>
            <Toast
                toastList={list}
                position={"top-right"}
                autoDelete={true}
                dismissTime={3000}
            />
            <div className="pcoded-main-container">
                <ModalWrapper
                    header="Delete"
                    title="Do you want to delete this room?"
                    show={showDeleteModal}
                    element={singleRoom}
                    onDeleteHandler={onDeleteHandler}
                    closeModal={closeModal}
                />
                <div className="card p-3 mb-0">
                    <div className="d-flex justify-content-between align-items-center">
                        {
                            !edit ?
                                <>
                                    <div className="d-flex">
                                        <h6 className="custom-cursor breadcumBarItem" onClick={() => history.push('/property-list')}>Property List  &nbsp;</h6>
                                        <h6 className="">{`${' '} > ${location.state.propertyName}`}</h6>
                                    </div>
                                    <h6 className="m-0">{(showRoomList) ? `Room List` : ` Add Room`}</h6>
                                </>
                                :
                                <>
                                    <div className="d-flex">
                                        <h6 className="custom-cursor breadcumBarItem" onClick={() => history.push('/property-list')}>Property List  &nbsp;</h6>
                                        <h6 className="">{`${' '} > ${location.state.propertyName}`}</h6>
                                    </div>
                                    <h6 className="m-0">{(!showRoomList && edit) ? `Edit Room` : ``}</h6>
                                </>

                        }
                        <div className="">
                            <button className="text-capitalize btn btn-info m-0" onClick={() => {
                                setShowRoomList(!showRoomList)
                                setEdit(false)
                            }}><i className="feather icon-plus"></i>{showRoomList ? `Add Room` : `Room List`}</button>
                        </div>
                    </div>
                </div>
                {showRoomList ?
                    <div className="pcoded-wrapper">
                        <div className="pcoded-content">
                            <div className="pcoded-inner-content">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className={!loader ? "card" : ''}>
                                            {
                                                !loader ?
                                                    <>
                                                        {/* <div style={{fontSize: 14, color: '#000', paddingLeft: 15, paddingTop: 20, display: 'flex'}}>
                                                           Property Name: {'   '}
                                                           <h5 style={{paddingLeft: 10}} className="mb-0">{`${location.state.propertyName}`}</h5>
                                                        </div> */}
                                                        <div className="card-body">
                                                            <BootstrapTable keyField='id' data={roomsList} columns={columns} />
                                                        </div>
                                                        {
                                                            roomsList.length <= 0 ?
                                                                <div className="card-body text-center pt-0">
                                                                    <span className="font-weight-bold h6">No room found.</span>
                                                                </div> : null
                                                        }
                                                    </>
                                                    :
                                                    <div className="text-center">
                                                        <Loader
                                                            type="Puff"
                                                            color="#00BFFF"
                                                            height={30}
                                                            width={100}
                                                        />
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <RoomAddEdit showRoomList={showRoomList} setShowRoomList={setShowRoomList} errorToast={errorToast} singleRoom={singleRoom} updateTable={updateTable} edit={edit} successToast={successToast} />
                }
            </div>
        </div>
    );
}

const RoomAddEdit = ({ showRoomList, setShowRoomList, errorToast, edit = false, singleRoom, updateTable, successToast }) => {

    let facilitiesArr = [
        { name: "ac", value: "AC", checked: false },
        { name: "chair", value: "Chair", checked: false },
        { name: "tv", value: "TV", checked: false },
        { name: "washroom", value: "Washroom", checked: false },
        { name: "fridge", value: "Fridge", checked: false },
        { name: "food", value: "Food", checked: false },
        { name: "cctv", value: "CCTV", checked: false },
        { name: "bed", value: "Bed", checked: false },
        { name: "locker", value: "Locker", checked: false },
    ];

    const property = useSelector(state => state.property);
    const location = useLocation();

    const [roomNumber, setRoomNumber] = useState('');
    const [selectedPG, setSelectedPG] = useState('');

    const [floor, setFloor] = useState('');
    const [floorArr, setFloorArr] = useState('');

    const [sharingType, setSharingType] = useState('');

    const [unit, setUnit] = useState('');

    const [unitFinal, setUnitFinal] = useState('');
    const [subUnit, setSubUnit] = useState('');

    const [rentAmount, setRentAmount] = useState('');
    const [roomRemark, setRoomRemark] = useState('');

    const [checkedState, setCheckedState] = useState(
        // new Array(facilitiesArr.length).fill(false)
        {
            "ac": false,
            "chair": false,
            "tv": false,
            "washroom": false,
            "fridge": false,
            "food": false,
            "cctv": false,
            "bed": false,
            "locker": false
        }
    );

    const [roomNumberError, setRoomNumberError] = useState('');
    const [selectedPGError, setSelectedPGError] = useState('');
    const [floorError, setfloorError] = useState('');
    const [sharingTypeError, setSharingTypeError] = useState('');

    const [unitError, setUnitError] = useState('');
    const [subUnitError, setSubUnitError] = useState('');

    const [rentAmountError, setRentAmountError] = useState('');
    const [roomRemarkError, setRoomRemarkError] = useState('');
    const [facilityError, setFacilityError] = useState('');

    useEffect(() => {
        if (edit) {
            updateFields();
        }
    }, [edit])

    useEffect(() => {
        onAddUpdate();
    }, [])

    const onAddUpdate = () => {
        if (!edit) {

            setSelectedPG(location.state.propertyId);

            var keys = Object.keys(property.singleProperty.floor);
            var filtered = keys.filter(function (key) {
                return property.singleProperty.floor[key]
            });
            keys = filtered.filter(k => k != '__v' && k != '_id' && k != 'created' && k != 'propertyId');
            setFloorArr(keys)
        }
    }

    const updateFields = () => {
        setSelectedPG(singleRoom.propertyId);
        setRoomNumber(singleRoom.roomNo);

        var keys = Object.keys(property.singleProperty.floor);
        var filtered = keys.filter(function (key) {
            return property.singleProperty.floor[key]
        });
        keys = filtered.filter(k => k != '__v' && k != '_id' && k != 'created' && k != 'propertyId');
        setFloorArr(keys)

        setFloor(singleRoom.floor);

        setSharingType(singleRoom.sharingTypes);
       
        if(singleRoom.unitType == 'room'){
            setUnit(singleRoom.unitType);
        } else {
            setUnit(singleRoom.unitSubType ? singleRoom.unitSubType : '');
        }

        setSubUnit(singleRoom.unitSubType ? singleRoom.unitSubType : '')
        setRentAmount(singleRoom.rentAmount);
        setRoomRemark(singleRoom.roomRemark);
        setCheckedState(singleRoom.facilities);
    }

    const digitValidator = (value) =>
        value && !/^[0-9]+$/i.test(value)
            ? true
            : false;

    const alphaNumericValidator = (value) =>
        value && !/^[a-z0-9]+$/i.test(value)
            ? true
            : false;

    const validate = () => {
        let roomNumberError = '';
        let selectedPGError = '';
        let floorError = '';
        let sharingTypeError = '';
        let unitError = '';
        let rentAmountError = '';
        let roomRemarkError = '';
        let facilityError = '';
        // let subUnitError = '';

        if (roomNumber === '') {
            roomNumberError = "Room number is required"
        }
        if (alphaNumericValidator(roomNumber)) {
            roomNumberError = "Room number should have digits and alphabets only"
        }
        if (selectedPG === '') {
            selectedPGError = "PG is required"
        }
        if (floor === '') {
            floorError = "Floor is required"
        }
        if (sharingType === '') {
            sharingTypeError = "Sharing type is required"
        }
        if (unit === '') {
            unitError = "Unit is required"
        }
        // if (subUnit === '') {
        //     subUnitError = "Sub unit is required"
        // }
        if (rentAmount === '') {
            rentAmountError = "Rent amount is required"
        }
        if (digitValidator(rentAmount)) {
            rentAmountError = 'Rent amount must contain only digits';
        }
        if (roomRemark === '') {
            roomRemarkError = "Room remark is required"
        }
        if (!Object.keys(checkedState).some(k => checkedState[k])) {
            facilityError = "Facility is required"
        }
        if (roomNumberError || selectedPGError || floorError || sharingTypeError || unitError || rentAmountError || roomRemarkError || facilityError) {
            setRoomNumberError(roomNumberError);
            setSelectedPGError(selectedPGError);
            setfloorError(floorError);
            setSharingTypeError(sharingTypeError);
            setUnitError(unitError);
            setRentAmountError(rentAmountError)
            setRoomRemarkError(roomRemarkError)
            setFacilityError(facilityError)
            return false;
        }
        return true;
    };

    const handleOnChange = async (key) => {
        let newCheckedState = { ...checkedState };
        newCheckedState[key] = !newCheckedState[key]
        setCheckedState(newCheckedState);
        setFacilityError('')
    }

    const onSubmit = async () => {
        console.log('I AM DATA')

        if (!edit) {
            try {
                const isValid = validate();
                if (isValid) {
                    let unitTypeFinal="";
                    let subUnitFinal="";
                    switch (true) {
                        case unit == 'room':
                            unitTypeFinal = unit;
                            subUnitFinal = sharingType;
                            break;
                        case unit.includes('RK'):
                            unitTypeFinal = 'RK';
                            subUnitFinal = unit;
                            break;
                        case unit.includes('BHK'):
                            unitTypeFinal = 'BHK';
                            subUnitFinal = unit;
                            break;
                        case unit.includes('studioApartment'):
                            unitTypeFinal = 'studioApartment';
                            subUnitFinal = unit;
                            break;
                        default:
                            break;
                    }
                    const data = {
                        propertyId: selectedPG,
                        roomNo: roomNumber,
                        floor: floor,
                        sharingTypes: sharingType,
                        unitType: unitTypeFinal,
                        unitSubType: subUnitFinal,
                        rentAmount: rentAmount,
                        roomRemark: roomRemark,
                        facilities: checkedState,
                    }
                    const response = await addNewRoom(data);
                    if (response) {
                        setShowRoomList(true);
                        updateTable(response);
                        successToast('Room Added Successfully.');
                    }
                }
            } catch (err) {
                if (err.message) {
                    errorToast(err.message);
                }
            }
        } else {
            onSubmitEdit();
        }
    }

    const onSubmitEdit = async () => {
        try {
            const isValid = validate();
            if (isValid) {
                    let unitTypeFinal="";
                    let subUnitFinal="";
                    switch (true) {
                        case unit == 'room':
                            unitTypeFinal = unit;
                            subUnitFinal = sharingType;
                            break;
                        case unit.includes('RK'):
                            unitTypeFinal = 'RK';
                            subUnitFinal = unit;
                            break;
                        case unit.includes('BHK'):
                            unitTypeFinal = 'BHK';
                            subUnitFinal = unit;
                            break;
                        case unit.includes('studioApartment'):
                            unitTypeFinal = 'studioApartment';
                            subUnitFinal = unit;
                            break;
                        default:
                            break;
                    }
                const data = {
                    propertyId: selectedPG,
                    roomNo: roomNumber,
                    floor: floor,
                    sharingTypes: sharingType,
                    unitType: unitTypeFinal,
                    unitSubType: subUnitFinal,
                    rentAmount: rentAmount,
                    roomRemark: roomRemark,
                    facilities: checkedState,
                }
                const response = await updateRoom(data, singleRoom._id);
                if (response) {
                    setShowRoomList(true);
                    updateTable(response, singleRoom._id);
                    successToast('Room Edited Successfully.');
                }
            }
        } catch (err) {
            if (err.message) {
                errorToast(err.message);
            }
        }
    }

    return (
        <div className="pcoded-wrapper">
            <div className="pcoded-content">
                <div className="pcoded-inner-content">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <form>
                                        <div className="form-group  mb-0 col">
                                            <label htmlFor="managersName" className="form-label">PG Name</label>
                                            <select disabled className="form-control" value={selectedPG} onChange={(e) => {
                                                setSelectedPG(e.target.value)
                                                setSelectedPGError('')
                                            }}>
                                                <option value=''>Select</option>
                                                {property.propertyList && property.propertyList.map((option) => (
                                                    // <></>
                                                    <option key={option._id} value={option._id}>{option.propertyName}</option>
                                                ))}
                                            </select>
                                            <p className="text-left p-1 mb-1 text-danger">{selectedPGError !== '' ? selectedPGError : ''}</p>
                                        </div>
                                        <div className="form-group mb-0 col">
                                            <label htmlFor="propertyName" className="form-label">Room Number</label>
                                            <Input className="mb-3 d-block" type="text" placeholder="Room Number" value={roomNumber} set={setRoomNumber} onFocus={() => setRoomNumberError('')} validation={roomNumberError} />
                                        </div>
                                        <div className="form-group mb-0 col">
                                            <label htmlFor="preferredTenants" className="form-label">Floor</label>
                                            <select className="form-control" value={floor} onChange={(e) => {
                                                setFloor(e.target.value)
                                                setfloorError('')
                                            }}>
                                                <option value=''>Select</option>
                                                {floorArr && floorArr.map((key, index) => {

                                                    let abc = key.replace('Floor', ' Floor').split(' ');
                                                    let finalString = '';
                                                    abc.forEach((str) => {
                                                        finalString = finalString + ' ' + str.replace(
                                                            /\w\S*/g,
                                                            function (txt) {
                                                                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                                                            }
                                                        );
                                                    })
                                                    return (
                                                        // <></>
                                                        <option key={index} value={key}>{finalString}</option>
                                                    )
                                                })}
                                            </select>
                                            <p className="text-left p-1 mb-1 text-danger">{floorError !== '' ? floorError : ''}</p>
                                        </div>
                                        <div className="form-group mb-0 col">
                                            <label htmlFor="preferredTenants" className="form-label">Sharing Type</label>
                                            <select className="form-control" value={sharingType} onChange={(e) => {
                                                setSharingType(e.target.value)
                                                setSharingTypeError('')
                                            }}>
                                                <option value=''>Select</option>
                                                <option value="1Sharing">Single Room</option>
                                                <option value="2Sharing">Double Room</option>
                                                <option value="3Sharing">Triple Room</option>
                                                <option value="4Sharing">4 Sharing</option>
                                                <option value="5Sharing">5 Sharing</option>
                                                <option value="6Sharing">6 Sharing</option>
                                                <option value="7Sharing">7 Sharing</option>
                                                <option value="8Sharing">8 Sharing</option>
                                                <option value="9Sharing">9 Sharing</option>
                                            </select>
                                            <p className="text-left p-1 mb-1 text-danger">{sharingTypeError !== '' ? sharingTypeError : ''}</p>
                                        </div>
                                        {/* Unit Type */}
                                        <div className="form-group mb-0 col">
                                            <label htmlFor="preferredTenants" className="form-label">Unit Type</label>
                                            <select className="form-control" value={unit} onChange={(e) => {
                                                setUnit(e.target.value)
                                                setSubUnit('')
                                                setUnitError('')
                                            }}>
                                                <option value=''>Select</option>
                                                <option value="room">Room</option>
                                                <option value="1RK">1 RK</option>
                                                <option value="2RK">2 RK</option>
                                                <option value="1BHK">1 BHK</option>
                                                <option value="2BHK">2 BHK</option>
                                                <option value="3BHK">3 BHK</option>
                                                <option value="4BHK">4 BHK</option>
                                                <option value="5BHK">5 BHK</option>
                                                <option value="studioApartment">Studio Apartment</option>
                                            </select>
                                            <p className="text-left p-1 mb-1 text-danger">{unitError !== '' ? unitError : ''}</p>
                                        </div>
                                        {/* Sub Unit Type */}
                                        {/* <div className="form-group mb-0 col">
                                            <label htmlFor="preferredTenants" className="form-label">Sub Unit Type</label>
                                            <select className="form-control" value={subUnit} onChange={(e) => {
                                                setSubUnit(e.target.value)
                                                setSubUnitError('')
                                            }}>
                                                <option value=''>Select</option>
                                                {
                                                    unit === 'room' ?
                                                    <>
                                                        <option value="1Sharing">1 Sharing</option>
                                                        <option value="2Sharing">2 Sharing</option>
                                                        <option value="3Sharing">3 Sharing</option>
                                                        <option value="4Sharing">4 Sharing</option>
                                                        <option value="5Sharing">5 Sharing</option>
                                                        <option value="6Sharing">6 Sharing</option>
                                                    </>
                                                    : unit === 'RK' ?
                                                    <>
                                                        <option value="1RK">1 RK</option>
                                                        <option value="2RK">2 RK</option>
                                                        <option value="3RK">3 RK</option>
                                                    </>
                                                    :unit === 'BHK' ?
                                                    <>
                                                        <option value="1BHK">1 BHK</option>
                                                        <option value="2BHK">2 BHK</option>
                                                        <option value="3BHK">3 BHK</option>
                                                        <option value="4BHK">4 BHK</option>
                                                        <option value="5BHK">5 BHK</option>
                                                    </>
                                                    : unit  === "studioApartment" ?
                                                    <option value="studioApartment">Studio Apartment</option>
                                                    :
                                                    null
                                                }
                                                
                                            </select>
                                            <p className="text-left p-1 mb-1 text-danger">{subUnitError !== '' ? subUnitError : ''}</p>
                                        </div> */}
                                        <div className="form-group mb-0 col">
                                            <label htmlFor="managersName" className="form-label">Rent Amount</label>
                                            <Input className="mb-3 d-block" type="text" maxLength="20" placeholder="Rent Amount" value={rentAmount} set={setRentAmount} onFocus={() => setRentAmountError('')} validation={rentAmountError} />
                                        </div>
                                        <div className="form-group mb-0 col">
                                            <label htmlFor="pgContact" className="form-label">Room Remark</label>
                                            <Input className="mb-3 d-block" type="text" maxLength="10" placeholder="Room Remark" value={roomRemark} set={setRoomRemark} onFocus={() => setRoomRemarkError('')} validation={roomRemarkError} />
                                        </div>
                                        <div className="form-group mb-0 col">
                                            <label htmlFor="pgContact" className="form-label">Facilities</label>
                                            <div className="form-group form-check" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                {
                                                    facilitiesArr.map((item, index) => {
                                                        return (
                                                            <div key={index} className="left-section" style={{ padding: 20 }}>
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id={`custom-checkbox-${index}`}
                                                                    name={item.name}
                                                                    value={item.value}
                                                                    checked={checkedState[item.name]}
                                                                    onChange={() => handleOnChange(item.name)}
                                                                    style={{ cursor: 'pointer' }}
                                                                />
                                                                <label style={{ cursor: 'pointer' }} className="form-check-label" htmlFor={`custom-checkbox-${index}`}>{item.value}</label>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <p className="text-left p-1 text-danger mb-0">{facilityError !== '' ? facilityError : ''}</p>
                                        </div>
                                        <div className="form-row d-flex justify-content-end">
                                            <button className="text-capitalize btn btn-info m-0" onClick={(e) => {
                                                e.preventDefault();
                                                onSubmit();
                                            }} type="submit">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Input = ({ className, placeholder, type, value, set, validation = '', maxLength, pattern, onFocus }) => {
    return (
        <>
            <div className={`form-group col${className}`}>
                <input type={type} pattern={pattern} className="form-control w-100" placeholder={placeholder} onFocus={onFocus} value={value} maxLength={maxLength} onChange={(e) => { set(e.target.value) }} />
                <p className="text-left p-1 text-danger mb-0">{validation !== '' ? validation : ''}</p>
            </div>
        </>
    )
}

export default RoomsList;