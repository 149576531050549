import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import jwtDecode from "jwt-decode";
import { Redirect, Route } from "react-router-dom";
import moment from 'moment';

const UnprotectedRoute = ({ component: Component, ...restOfProps }) => {
	const login = useSelector(state => state.login);
	const history = useHistory();

	const[checkAuthState, setCheckAuthState]= useState(false);

	useEffect(() => {
		checkAuth();
	}, [])

	const logout = async() => {
		await localStorage.clear();
		setCheckAuthState(false);
		history.push('/signin');
	} 

	const checkAuth = async() => {
		const userData = await localStorage.getItem('userAuthToken');
		if (userData) {
			var decoded = jwtDecode(userData);
			var currentTime = Math.floor(moment(new Date()).valueOf() / 1000);
			if (decoded.exp - currentTime <= 900) {
				logout();
			} else {
				setCheckAuthState(true)
				history.push('/');
			}
		}
		else if (!userData) {
			setCheckAuthState(false)
			logout();
		}
	}
	return(
		<Route
		{...restOfProps}
		render={(props) =>
			<Component {...props} />
		}
	  />
	)
}
export default UnprotectedRoute;
