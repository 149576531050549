/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { NavLink } from 'react-router-dom';


class NavList extends React.Component {
    // reload = () => {
    //     window.location.reload();
    // }

    render() {
        return (
            <ul className="list-unstyled nav-list clearfix">
                <li>
                    <NavLink activeClassName="active" to="/" exact >
                        <img style={{ width: 25, color: '#FFCE47' }} src="/images/laptop.png" alt="icon" />
                        <span style={{ marginLeft: 50, color: '#FFCE47' }} className="name">Dashboard</span>
                    </NavLink>
                </li>
            </ul>
        );
    }
}

export default NavList;
