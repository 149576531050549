import { OWNER_LIST } from '../actions/actions';

const authDefaultState = {
    ownerList: []
};
export default function (state = authDefaultState, action) {
    switch (action.type) {
        case OWNER_LIST:
            return { ...state, ownerList: action.payload };
        default:
            return state;
    }
}