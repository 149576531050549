import React from 'react';
import { connect } from 'react-redux';
import SiteHead from '../header';
import Nav from '../nav';
// import { modalAction } from '../modal/modalAction';
// import { successAction } from '../success/successAction';
// import { Hospitals } from '../hospitals/Hospitals';

export default function layoutWrapper(WrapperComponent) {
    // eslint-disable-next-line react/prefer-stateless-function
    class LayoutWrapper extends React.Component {
        componentWillReceiveProps(props) {
            props.success != null && setTimeout(() => {
                this.props.dispatch(successAction(null));
            }, 2500);
        }
        navMini = () => {
        //     this.props.dispatch(modalAction({ menu: !this.props.modal.menu }));
        }
        render() {
            const { dispatch } = this.props;
            // const navMini = this.props.modal.menu;
            return (
                <React.Fragment>
                    <Nav  />
                    <SiteHead  />
                    <div className={`content-container`}>
                        <div className="page-wrapper">
                            <WrapperComponent />
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    }

    const mapStateToProps = state => ({
        login: state.login,
        router: state.router,
        modal: state.modal,
        success: state.success
    });

    return connect(mapStateToProps)(LayoutWrapper);
}
