import { PROPERTY_LIST, SINGLE_PROPERTY } from '../actions/actions';

const authDefaultState = {
    propertyList: [],
    singleProperty: {}
};
export default function (state = authDefaultState, action) {
    switch (action.type) {
        case PROPERTY_LIST:
            return { ...state, propertyList: action.payload };
        case SINGLE_PROPERTY:
            return { ...state, singleProperty: action.payload };
        default:
            return state;
    }
}